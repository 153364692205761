@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, "Roboto", sans-serif, "Gochi Hand", cursive;
}
.gochi {
  font-family: "Gochi Hand", cursive;
}
.font-rob {
  font-family: var(--text-font);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: #a75000;
  border-radius: 12px;
}
::-webkit-scrollbar-thumb:hover {
  background: #fff9bb;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}
td,
th {
  padding: 2%;
}

/* Animation (Add After) */
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}
@font-face {
  font-family: "Brush Script MT";
  src: url("./fonts/BRUSHSCI.TTF") format("truetype");
  /* Adjust the file path and format based on the font file you have */
}

.card {
  box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.75s ease;
  opacity: 0;
  position: absolute;
  transform: scale(0.85) translateY(50px);
  margin-inline: auto;
}
.divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #c2c2c2;
  margin-block: 5%;
}
.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #c2c2c2;
}
.divider::before {
  margin-right: 0.25em;
}
.divider::after {
  margin-left: 0.25em;
}

.card h2,
.card p {
  line-height: 1;
  margin: 0;
}
label {
  text-align: left;
}
.card.active {
  opacity: 1;
  transform: scale(1) translateY(0);
  box-shadow: 0 30px 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.card.next {
  opacity: 0;
  z-index: 0;
}
.card.prev {
  transform: scale(1.1) translateY(-50px);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
}

.welcome {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.welcome > *:nth-child(n) {
  flex-basis: 100%;
}

.welcome > *:nth-child(1) {
  flex: 0 0 5%;
  margin-bottom: 1rem;
}

.modalstyle {
  overflow: scroll;
}

.hovericon:hover {
  cursor: pointer;
}
.shopanimation {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 95%;
  margin-top: -25%;
  /* margin-left: 1vw; */
}
.shopdoor1,
.shopdoor2 {
  /* position: absolute; */
  width: 40vw;
  /* margin-inline: 0.2vw; */
  background: rgba(69, 175, 232, 0.2);
  /* background: olive; */
  backdrop-filter: blur(12px);
  z-index: 5;
  height: 75vh;
  margin-top: -23vh;
  animation-fill-mode: both;
}

.shopdoor1 {
  animation-name: shopd1;
  animation-duration: 10s;
}

.shopdoor2 {
  animation-name: shopd2;
  animation-duration: 10s;
}

@keyframes shopd1 {
  0% {
    margin-right: 15vw;
  }
  25% {
    margin-right: 35vw;
  }
  50% {
    margin-right: 50vw;
  }
  75% {
    margin-right: 65vw;
  }
  100% {
    margin-right: 85vw;
    background-color: transparent;
  }
}
@keyframes shopd2 {
  0% {
    margin-left: 15vw;
  }
  25% {
    margin-left: 35vw;
  }
  50% {
    margin-left: 50vw;
  }
  75% {
    margin-left: 65vw;
  }
  100% {
    margin-left: 85vw;
    background-color: transparent;
  }
}

.timeline-container {
  display: "flex";
  position: relative;
  width: "100%";
}

/* Chat Container */
.chat-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 50vh; /* Adjust the height as needed */
  padding: 10px;
}

/* Chat Message */
.chat-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.chat-message.sent {
  align-items: flex-end;
}

.chat-message .message {
  background-color: #dcf8c6; /* Color for sent messages */
  color: black;
  padding: 10px;
  border-radius: 10px;
}

.chat-message.sent .message {
  background-color: #e1ffc7; /* Color for received messages */
}
.chat-message.received .message {
  background-color: #f4b082; /* Color for received messages */
}
.chat-message .metadata {
  font-size: 12px;
  color: #888;
}

/* Input Container */
.input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.input-container input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
}

.input-container button {
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
}
/* .loading{
  filter: blur(10px);
  transition: filter 0.5s ease-out;
} */
/* .carousel{
  height:50vh;
color: #f4b082;
} */
h2 {
  color: white;
  font-family: "Brush Script MT";
  font-size: 34px;
}
h3 {
  color: #01337e;
  font-family: "Brush Script MT";
  font-size: 24px;
}
.activeCard {
  height: 30vh;
  width: 25rem;
  opacity: 1;
  z-index: 4;
}
.matchingImg {
  height: 26vh;
  width: 25vh;
  opacity: 1;
  z-index: 4;
}
.activeArtCard {
  height: 30vh;
  width: 40vh;
  opacity: 1;
  z-index: 4;
}

.cardMedia {
  opacity: 0.5;
}
@media (max-width: 600px) {
  .cardMedia {
    visibility: hidden;
  }
  .activeArtCard {
    height: 65vh;
    width: 10vh;
    opacity: 1;
    z-index: 4;
    object-fit: cover;
  }
}
.carouselContainer {
  padding: 1rem;
}
.inputdiv {
  display: flex;
  flex-direction: column;
}
input,
textarea {
  padding: 1vh;
  width: 100%;
  margin-bottom: 1.5vh;
  margin-top: 0.8vh;
  border: none;
  text-decoration: none;
}
.submitbutton {
  background-color: #01337e;
  color: whitesmoke;
  padding: 2%;
  border-radius: 12%;
}
/* a{
  color: whitesmoke;
  text-decoration: none;
} */
form {
  text-align: left;
}
button {
  margin: 1%;
  padding: 1%;
}
.catalogue-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  background: transparent;
  padding: 10px;
  border-radius: 12%;
  border: none;
  cursor: pointer;
  color: #01337e;
}

.catalogue-button:hover {
  background: #01337e;
  color: whitesmoke;
}
